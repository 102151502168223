import React from 'react';
import PropTypes from 'prop-types';


function RichText({ content, ...rest }) {
  return (
      <>
        <div dangerouslySetInnerHTML={{ __html: content }} {...rest} />
      </>  
  );
}

RichText.propTypes = {
  content: PropTypes.node.isRequired,
};

export default RichText;