/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import _ from "lodash"

const ButtonLink = ({ children, href, variant, ...rest }) => {
  const internal = /^\/(?!\/)/.test(href)
  let styles = {
    variant: "text.btn",
    p: "22.4px 36px",
    borderRadius: "4px",
    display: ["block", null, "inline-block"],
    opacity: "1",
    textAlign: "center",
    cursor: "pointer",
    backgroundColor: "primary",
    color: "background",
    border: "2px solid",
    borderColor: "primary",
    "&:hover": {
      backgroundColor: "secondary",
      borderColor: "secondary"
    },
  }

  if (variant === "outlined") {
    _.assign(styles, {
      backgroundColor: "background",
      color: "primary",
      "&:hover": {
        borderColor: "secondary",
        color: "secondary",
      },
    })
  }

  if (internal) {
    return (
      <Link to={href} {...rest} sx={{ ...styles }}>
        {children}
      </Link>
    )
  }
  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      {...rest}
      sx={{ ...styles }}
    >
      {children}
    </a>
  )
}

ButtonLink.propTypes = {
  href: PropTypes.string,
  variant: PropTypes.string,
  children: PropTypes.node,
}

ButtonLink.defaultProps = {
  href: "",
  variant: "default",
}

export default ButtonLink