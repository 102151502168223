/** @jsx jsx */
import { jsx, Styled } from "theme-ui"
import PropTypes from "prop-types"

import {
    Container,
    ButtonLink    
} from "../components"

const CTABanner = ({
    title,
    ctatext,
    ctaurl,
    bgcolor,
    ...rest
}) => {
    return(
        <Wrapper bgcolor={bgcolor} {...rest}>
            <Container>
                <div sx={{
                    textAlign: "center",
                    maxWidth: "840px",
                    mx: "auto",
                }}>
                    <Styled.h2 sx={{ mt:"0", mb: "32px"}}>{ title }</Styled.h2>
                    { ctatext &&(
                        <ButtonLink href={ctaurl} sx={{ display: "inline-block"}}>{ctatext}</ButtonLink>
                    )}
                </div>                
            </Container>
        </Wrapper>
    )
}

const Wrapper = ({ bgcolor, children, ...rest }) => (
    <div
      sx={{
        position: "relative",
        py: ["45px",null,"144px"],
        backgroundColor: bgcolor,
      }}
      {...rest}
    >
      {children}
    </div>
)

CTABanner.propTypes = {
    title: PropTypes.string,
    ctatext: PropTypes.string,
    ctaurl: PropTypes.string,
    bgcolor: PropTypes.string
}

export default CTABanner