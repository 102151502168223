/** @jsx jsx */
import { jsx, Embed } from "theme-ui"
import PropTypes from "prop-types"

import {Container} from "../components"

const EmbedVideo = ({
    video,
    ...rest
}) => {
    return(
        <Wrapper {...rest}>
            <Container>
                <Embed
                src={video}
                />
            </Container>
        </Wrapper>
    )
}

const Wrapper = ({ children, ...rest }) => (
    <div
      sx={{
        position: "relative",
        py: "72px",
      }}
      {...rest}
    >
      {children}
    </div>
)   

EmbedVideo.propTypes = {
    video: PropTypes.string
}

export default EmbedVideo