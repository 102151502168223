/** @jsx jsx */
import { jsx, Styled, Label, Input, Textarea, Button } from "theme-ui"
import PropTypes from "prop-types"

import { Container } from "../components"

const ContactForm = ({ title, ...rest }) => {
  return (
    <Wrapper {...rest}>
      <Container>
        <div
          sx={{
            maxWidth: "672px",
            mx: "auto",
          }}
        >
          {title && (
            <Styled.h2
              sx={{
                mt: "0",
                mb: [null, null, "134px"],
              }}
            >
              {title}
            </Styled.h2>
          )}
          <form
            action="/thank-you"
            name="contact"
            method="POST"
            netlify="true"
            netlify-honeypot="bot-field"
          >
            <input
              type="hidden"
              aria-label="Contact"
              name="form-name"
              value="contact"
            />
            <input type="hidden" aria-label="Bot Field" name="bot-field" />
            <Label htmlFor="firstname" sx={{ mb: "4px" }}>
              First Name <span sx={{ color: "primary", ml: "5px" }}>*</span>
            </Label>
            <Input name="firstname" mb="30px" required />
            <Label htmlFor="lastname" sx={{ mb: "4px" }}>
              Last Name <span sx={{ color: "primary", ml: "5px" }}>*</span>
            </Label>
            <Input name="lastname" mb="30px" required />
            <Label htmlFor="phone" sx={{ mb: "4px" }}>
              Phone <span sx={{ color: "primary", ml: "5px" }}>*</span>
            </Label>
            <Input name="phone" mb="30px" required />
            <Label htmlFor="email" sx={{ mb: "4px" }}>
              Email <span sx={{ color: "primary" }}>*</span>
            </Label>
            <Input name="email" type="email" mb="30px" required />
            <Label htmlFor="propertyaddress" sx={{ mb: "4px" }}>
              Property Address (if quoting home)
            </Label>
            <Input name="propertyaddress" mb="30px" />
            <Label htmlFor="driver1" sx={{ mb: "4px" }}>
              Driver #1 First and Last Name (if quoting auto)
            </Label>
            <Input name="driver1" mb="30px" />
            <Label htmlFor="driver1dob" sx={{ mb: "4px" }}>
              Driver #1 DOB
            </Label>
            <Input name="driver1dob" type="date" mb="30px" />
            <Label htmlFor="driver2" sx={{ mb: "4px" }}>
              Driver #2 First and Last Name (if quoting auto)
            </Label>
            <Input name="driver2" mb="30px" />
            <Label htmlFor="driver2dob" sx={{ mb: "4px" }}>
              Driver #2 DOB
            </Label>
            <Input name="driver2dob" type="date" mb="30px" />
            <Label htmlFor="driver3" sx={{ mb: "4px" }}>
              Driver #3 First and Last Name (if quoting auto)
            </Label>
            <Input name="driver3" mb="30px" />
            <Label htmlFor="driver3dob" sx={{ mb: "4px" }}>
              Driver #3 DOB
            </Label>
            <Input name="driver3dob" type="date" mb="30px" />
            <Label htmlFor="vehiclesvinnumber" sx={{ mb: "4px" }}>
              Vehicle(s) VIN Numbers (if quoting auto)
            </Label>
            <Textarea name="vehiclesvinnumber" rows="20" mb="30px" />
            <Label htmlFor="notes" sx={{ mb: "4px" }}>
              Notes
            </Label>
            <Textarea name="notes" rows="20" mb="30px" />
            <Button variant="primary">Submit</Button>
          </form>
        </div>
      </Container>
    </Wrapper>
  )
}

const Wrapper = ({ children, ...rest }) => (
  <div
    sx={{
      position: "relative",
      py: "72px",
    }}
    {...rest}
  >
    {children}
  </div>
)

ContactForm.propTypes = {
  title: PropTypes.string,
}

export default ContactForm
