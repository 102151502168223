/** @jsx jsx */
import { jsx , Styled } from "theme-ui"
import PropTypes from "prop-types"

import {
    Container, 
    ButtonLink,    
    Row, 
    Col
} from "../components"

const SimpleHeroBanner = ({
    title,
    ctas,
    ...rest
}) => {
    return(
        <div sx={{ 
            bg:"tertiary", 
            py: ["45px",null,"144px"],
            minHeight:["500px",null,"auto"],
            display: ["flex",null,null],
            flexDirection: ["column",null,null],
            justifyContent: ["center",null,null],
            }}>
            <Container>
                <div 
                sx={{
                    color: 'text',
                    maxWidth: "850px"
                }}
                {...rest}>
                <Styled.h1 sx={{ 
                    mt: "0", 
                    mb: "32px",
                    letterSpacing: ["-1.2px",null,"normal"],
                    }}>{title}</Styled.h1>
                <Row>
                    {ctas.firstCTA.ctatext && (
                    <Col sx={{
                        flex: ["0 0 auto%", null, "0 0 auto%"],
                        maxWidth: "100%",
                        width: ["50%","auto",null],
                    }}>
                        <ButtonLink href={ctas.firstCTA.linkURL}>{ctas.firstCTA.ctatext}</ButtonLink>
                    </Col>
                    )}
                    {ctas.secondCTA.ctatext && (
                    <Col sx={{
                        flex: ["0 0 auto%", null, "0 0 auto%"],
                        maxWidth: "100%",
                        width: ["50%","auto",null],
                    }}>
                        <ButtonLink href={ctas.secondCTA.linkURL} variant="outlined" >{ctas.secondCTA.ctatext}</ButtonLink>
                    </Col>
                    )}
                </Row>
                
                </div>
            </Container>
        </div>
    )
}

SimpleHeroBanner.propTypes = {
    title: PropTypes.string,
    ctas: PropTypes.object
}
export default SimpleHeroBanner