/** @jsx jsx */
import { jsx , Styled } from "theme-ui"
import PropTypes from "prop-types"

import {
    Container, 
    Row, 
    Col,
    RichText,
    Image,
} from "../components"

const HomeAbout = ({
    data,
    ...rest
}) => {
    return(
        <Wrapper {...rest}>
            <Container>
                <Row>
                    <Col
                        sx={{
                            flex: [null, null, "0 0 66.66%"],
                            maxWidth: [null, "66.66%"],
                        }}
                    >
                        <Styled.h2 sx={{ 
                            mt: "0", 
                            mb: "32px",
                            letterSpacing: ["-1.2px",null,"normal"],
                            }}>{data.title}</Styled.h2>

                        <RichText content={data.description} />
                    </Col>
                    <Col
                        sx={{
                            flex: [null, null, "0 0 33.33%"],
                            maxWidth: [null, "33.33%"],
                        }}
                    >
                        <Image                         
                        image = {data.aboutimage}
                        alt = "featured image thumbnail for post"></Image>
                    </Col>
                </Row>
            </Container>
        </Wrapper>
    )
}

const Wrapper = ({ children, ...rest }) => (
    <div
      sx={{
        position: "relative",
        py: "72px",
      }}
      {...rest}
    >
      {children}
    </div>
)

HomeAbout.propTypes = {
    data: PropTypes.object
}

export default HomeAbout