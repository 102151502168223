/** @jsx jsx */
import { jsx } from "theme-ui"

export const Container = ({ children, ...rest }) => (
  <div
    {...rest}
    sx={{
      maxWidth: ["100%", "720px", "1170px"],
      width: "100%",
      px: ["20px", "15px"],
      mx: "auto",
    }}
  >
    {children}
  </div>
)

export const Row = ({ children, ...rest }) => (
  <div
    {...rest}
    sx={{
      display: "flex",
      flexWrap: "wrap",
      mx: "-15px",
    }}
  >
    {children}
  </div>
)

export const Col = ({ children, ...rest }) => (
  <div
    {...rest}
    sx={{
      width: "100%",
      px: "15px",
    }}
  >
    {children}
  </div>
)