/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql, useStaticQuery } from "gatsby"
import { Instagram, Linkedin } from "@styled-icons/bootstrap"
import moment from "moment"

import { Container, AnchorLink } from "../components"

const Footer = () => {
    const data = useStaticQuery(graphql`
      query {
        site {
          siteMetadata {
            title
            linkedin
            instagram
            copyright
          }
        }
      }
    `)
  
    const {
      title: siteTitle,
      linkedin,
      instagram,
      copyright,
    } = data.site.siteMetadata
  
    return (
        <footer sx={{ 
          bg: "#FFFFFF",
          py: ["45px",null,"144px"],
          textAlign: "center",
        }}>
            <Container>
                {/* Social Links */}
                <SocialLinks
                    instagram={instagram} linkedin={linkedin}
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        mb: ["22px",null,"70px"],
                    }}
                />
    
                {/* Copyright */}
                <div
                    sx={{
                        fontSize: ["0",null,"1"],
                        lineHeight: "1.87",
                        color: "secondary"
                    }}
                >
                    <Copyright siteTitle={siteTitle} copyright={copyright} />
                </div>
            </Container>
        </footer>
    )
}
  
const SocialLinks = ({ instagram,linkedin, ...rest }) => {    
    const aStyles = {
      fontSize: 1,
      textDecoration: "none",
      p: "19px",
      lineHeight: "normal",
      display: "block",
    }
    return (
      <div {...rest}>
        <ul
          sx={{
            listStyle: "none",
            m: "0",
            p: "0",
            display: "flex",
            flexWrap: "wrap",
            mx: "-15px",
            justifyContent: "center",
            '&:hover li a': {
                opacity: '0.65',                
            },
            '& li:hover a': {
                opacity: '1',                
            },
          }}
        >
          {instagram && (
            <li>
              <AnchorLink href={instagram} sx={{ ...aStyles, color: "primary" }}>
                <Instagram size={18} />
              </AnchorLink>
            </li>
          )}
          {linkedin && (
            <li>
              <AnchorLink href={linkedin} sx={{ ...aStyles, color: "primary" }}>
                <Linkedin size={18} />
              </AnchorLink>
            </li>
          )}
        </ul>
      </div>
    )
}
  
  const Copyright = ({ siteTitle,copyright, ...rest }) => (
    <div     
      {...rest}
    >
      © {moment().format("YYYY")} {siteTitle} | {copyright}
    </div>
  )
  
  export default Footer