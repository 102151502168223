/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql, useStaticQuery } from "gatsby"

import Image from "../components/image"
import AnchorLink from "../components/link"

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "cropped-My-Insurance-Dude.png" }) {
        name
        publicURL
      }
      site {
        siteMetadata {
          title          
        }
      }
    }
  `)
const { logo, site } = data
const { title: siteTitle } = site.siteMetadata
return (
    <header
        sx={{
        zIndex: "999",
        position: "relative",
        }}
    >
        <div sx={{ 
          position: "relative",
          padding: ["7px 22px","43px 85px"]
        }}>
       
          <LogoWrapper
            logo={logo.publicURL}
            title={siteTitle}
        />
        </div>
    </header>
    )
}

const LogoWrapper = ({ logo,title, ...rest }) => (
    <div
      sx={{
        display: "block",
        textAlign: ["center","left"]
      }}
      {...rest}
    >
      <AnchorLink
        href="/"
        sx={{ lineHeight: "1" }}
      >       
        <Image                         
              image = {logo}
              alt = {title} sx={{ maxWidth: ["100px","190px"] }} ></Image>
      </AnchorLink>
      <div
        sx={{
          display: [null, null, null, null, "none"],
          width: "40px",
        }}
      >
      </div>
    </div>
)
export default Header