/** @jsx jsx */
import { jsx, Styled } from "theme-ui"
import { Link } from "gatsby"
import PropTypes from "prop-types"

const AnchorLink = ({ href, children, ...rest }) => {
  const internal = /^\/(?!\/)/.test(href)

  return internal ? (
    <Link
      to={href}
      activeClassName="is-current"
      sx={{ variant: "text.a" }}
      {...rest}
    >
      {children}
    </Link>
  ) : (
    <Styled.a href={href} target="_blank" rel="noopener noreferrer" {...rest}>
      {children}
    </Styled.a>
  )
}

AnchorLink.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node,
}

AnchorLink.defaultProps = {
  href: "",
}

export default AnchorLink