/** @jsx jsx */
import { jsx, Styled } from "theme-ui"
import PropTypes from "prop-types"

import {
    Container, 
    Row, 
    Col,
    RichText,
    Image
} from "../components"

const Features = ({
    data,
    ...rest
}) => {
    return(
        <Wrapper {...rest}>
            <Container>
            {data && (
                <Row>
                     
                    {data.map((value, index) => {
                        return (<Col
                            sx={{
                                flex: [null, null, "0 0 50%"],
                                maxWidth: [null, "50%"],
                            }} key={index}
                        >
                            <div sx={{mb: ["60px",null,"144px"]}}>
                                <Image                         
                                    image = {value.icon}
                                    alt = {value.title}
                                    sx={{ width: "60px",mb: "32px"}}></Image>
                                
                                <Styled.h3 
                                sx={{
                                    mt: "0",
                                    mb: "16px",
                                }}>{value.title}</Styled.h3>
                                <RichText content={value.description}></RichText>                             
                            </div>
                        </Col> 
                        )
                    })}                  
                </Row>
            )}
            </Container>
        </Wrapper>
    )
}

const Wrapper = ({ children, ...rest }) => (
    <div
      sx={{
        position: "relative",
        py: "72px",
      }}
      {...rest}
    >
      {children}
    </div>
)

Features.propTypes = {
    data: PropTypes.array,
}

export default Features