/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"

import {
    Container, 
    Row, 
    Col,
    RichText,
} from "../components"

const Quote = ({
    quote,
    author,
    ...rest
}) => {
    return(
        <Wrapper {...rest}>
            <Container>
                <Row sx={{
                    justifyContent: [null,null,"flex-end"]
                }}>
                    <Col
                        sx={{
                            flex: [null, null, "0 0 80%"],
                            maxWidth: [null, "80%"],
                        }}
                    >
                        <RichText content={quote} sx={{
                            fontSize: ["6",null,"10"],
                            fontFamily: "heading",
                            color: "secondary",
                            lineHeight: "h2"
                        }}/>
                        {author && (
                            <div sx={{
                                fontSize: "0",
                                color: "#6e6e6e",
                                mt: "14px",
                            }}>
                                – {author}
                            </div>
                        )}
                    </Col>                    
                </Row>
            </Container>
        </Wrapper>
    )
}

const Wrapper = ({ children, ...rest }) => (
    <div
      sx={{
        position: "relative",
        py: "72px",
      }}
      {...rest}
    >
      {children}
    </div>
)

Quote.propTypes = {
    quote: PropTypes.string,
    author: PropTypes.string,
}

export default Quote