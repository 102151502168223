import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'

const Image = ({ image,alt,childImageSharp, ...rest }) => {

  if (!!image && !!childImageSharp) {
    return (
      <Img fluid={childImageSharp.fluid} alt={alt} {...rest} />
    )
  }

  if (!!childImageSharp) {
    return <Img fluid={childImageSharp.fluid} alt={alt} {...rest} />
  }

  if (!!image && typeof image === 'string')
    return <img src={image} alt={alt} {...rest} />

  return null
}

Image.propTypes = {  
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  alt: PropTypes.string,
  childImageSharp: PropTypes.object,
}

export default Image