/** @jsx jsx */
import { jsx , Styled } from "theme-ui"
import PropTypes from "prop-types"

import {
    Container, 
    Row, 
    Col,
    AnchorLink,
    RichText,
} from "../components"

const ContactIntro = ({
    title,
    locationtitle,
    contacttitle,
    location,
    email,
    mobile,
    ...rest
}) => {
    return(
        <Wrapper {...rest}>
            <Container>
                <div sx={{
                    maxWidth: "672px",
                    mx: "auto",
                }}>
                    <Styled.h1 sx={{
                        mt: "0",
                        mb: [null,null,"135px"],
                        textAlign: "center",
                        letterSpacing: "-1.44px",
                    }}>{ title }</Styled.h1>

                    <Row>
                        <Col sx={{
                            flex: [null, null, "0 0 50%"],
                            maxWidth: [null, "50%"],
                            textAlign: "center",
                        }}>
                            <Styled.h5 sx={{
                                mt: "0",
                                mb: "32px",
                                fontSize: [null,null,"27px"],
                            }}>{locationtitle}</Styled.h5>
                            <RichText content={location} />
                        </Col>
                        <Col sx={{
                            flex: [null, null, "0 0 50%"],
                            maxWidth: [null, "50%"],
                            textAlign: "center",
                        }}>
                            <Styled.h5 sx={{
                                mt: "0",
                                mb: "32px",
                                fontSize: [null,null,"27px"],
                            }}>{contacttitle}</Styled.h5>
                            Mobile:<br/>
                            <AnchorLink
                                href={`tel:${mobile}`}
                                sx={{ lineHeight: "1" }}
                            >       
                                {mobile}
                            </AnchorLink><br/>
                            Email:<br/>
                            <AnchorLink
                                href={`mailto:${email}`}
                                sx={{ lineHeight: "1" }}
                            >       
                                {email}
                            </AnchorLink>
                        </Col>
                    </Row>
                </div>
                
            </Container>
        </Wrapper>
    )
}

const Wrapper = ({ children, ...rest }) => (
    <div
      sx={{
        position: "relative",
        py: "72px",
      }}
      {...rest}
    >
      {children}
    </div>
)

ContactIntro.propTypes = {
    title: PropTypes.string,
    locationtitle: PropTypes.string,
    contacttitle: PropTypes.string,
    location: PropTypes.node,
    email: PropTypes.string,
    mobile: PropTypes.string,
}

export default ContactIntro